import * as React from "react";
import type { ComponentType } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withReactStrict: <T extends ComponentType<any>>(
	Component: T,
) => (props: React.ComponentProps<T>) => JSX.Element = (Component) =>
	function renderStrict(props) {
		return (
			<React.StrictMode>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
			</React.StrictMode>
		);
	};

import React from "react";

type Link = {
	title: string;
	path: string;
};

type Props = {
	links: Link[];
	poweredByLink: Link | null;
};

export function Footer({ links, poweredByLink }: Props): JSX.Element {
	const footerLinks = links.map(({ path, title }, index) => (
		<React.Fragment key={title}>
			{index > 0 && " | "}
			<a href={path}>{title}</a>
		</React.Fragment>
	));

	const poweredByLinkElement = poweredByLink ? (
		<a id="flexmr_link" href={poweredByLink.path}>
			{poweredByLink.title}
		</a>
	) : null;

	return (
		<div id="footer" data-testid="footerContainer">
			{poweredByLinkElement}
			{footerLinks}
		</div>
	);
}

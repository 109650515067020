import { Link } from "./links/Link";
import type { LinkGroups } from "@/pages/clientWidgets/types";

type Props = {
	links?: LinkGroups;
};

export function Links({ links }: Props): JSX.Element | null {
	const { left, right } = links ?? {};

	if (!right?.path || !right?.title) {
		return null;
	}

	// Rule is a false-positive, a href attribute is set within Link
	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	const rightLink = <Link path={right.path} title={right.title} />;

	if (!left?.path || !left?.title) {
		return rightLink;
	}

	return (
		<>
			<div style={{ float: "left" }}>
				{/* Rule is a false-positive, a href attribute is set within Link */}
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<Link path={left.path} title={left.title} />
			</div>
			{rightLink}
		</>
	);
}

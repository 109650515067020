import { getClassNames } from "./utils";

type Props = {
	type: string;
	title?: string;
	className?: string;
	role?: string;
	"data-testid"?: string;
};

export function Icon(props: Props): JSX.Element {
	const { type, role, title, className, "data-testid": dataTestId } = props;

	const classes = getClassNames(type, className);

	return (
		<i className={classes} title={title} role={role} data-testid={dataTestId} />
	);
}

export default Icon;

import { HeaderImage } from "./widgetHeading/HeaderImage";
import { HeaderTitle } from "./widgetHeading/HeaderTitle";

type Props = {
	colour?: string | null;
	title: string;
	titleLink?: string | null;
	imagePath?: string | null;
	imageAltText?: string | null;
};

export function WidgetHeading({
	colour,
	title,
	titleLink,
	imagePath,
	imageAltText,
}: Props): JSX.Element {
	if (imagePath) {
		return (
			<HeaderImage
				path={imagePath}
				altText={imageAltText}
				titleLink={titleLink}
			/>
		);
	}

	return <HeaderTitle title={title} titleLink={titleLink} colour={colour} />;
}

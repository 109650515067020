import { createMarkup } from "@flexmr/utils";
import type { WidgetConfiguration } from "../types";
import { Widget } from "../Widget";

type Custom = {
	linkTitle: string | null;
	linkPath: string | null;
	textilizedText: string | null;
};

type Props = WidgetConfiguration<Custom>;

export function FormattedText(props: Props): JSX.Element {
	const { custom, ...rest } = props;
	const { linkTitle, linkPath, textilizedText } = custom;

	const links =
		linkPath && linkTitle
			? { right: { path: linkPath, title: linkTitle } }
			: {};

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Widget {...rest} footerLinks={links}>
			{/* eslint-disable-next-line react/no-danger */}
			<p dangerouslySetInnerHTML={createMarkup(textilizedText ?? "")} />
		</Widget>
	);
}

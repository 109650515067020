import { getHeaderStyle } from "./utils";

type Props = {
	title: string;
	titleLink?: string | null;
	colour?: string | null;
};

function getTitle(
	title: string,
	titleLink: string | null = null,
): JSX.Element | null {
	if (title === "") {
		return null;
	}

	if (titleLink === null) {
		return <>{title}</>;
	}

	return <a href={titleLink}>{title}</a>;
}

export function HeaderTitle({ title, titleLink, colour }: Props): JSX.Element {
	return (
		<h1 className="widgetheading roundedtop" style={getHeaderStyle(colour)}>
			<span>{getTitle(title, titleLink)}</span>
		</h1>
	);
}

import type { FunctionComponent } from "react";
import ReactOnRails from "react-on-rails";
import { withReactStrict } from "./reactStrict";

interface FunctionOption {
	readonly component: FunctionComponent;
	readonly strict?: boolean;
}

type Components = Record<string, FunctionComponent | FunctionOption>;

export function strictRegister(components: Components): void {
	const strictComponents = Object.entries(components).reduce(
		(acc, [key, entity]) => {
			const { component, strict = true } =
				typeof entity === "function" ? { component: entity } : entity;

			return {
				...acc,
				[key]: strict ? withReactStrict(component) : component,
			};
		},
		{} as Record<string, FunctionComponent>,
	);

	ReactOnRails.register(strictComponents);
}

import "core-js/stable";
import "regenerator-runtime/runtime";

import { strictRegister } from "../utils/reactOnRails";
import { Footer } from "@/components/Layout/Footer";
import {
	Empty as EmptyWidget,
	FormattedText as FormattedTextWidget,
	Frontpage as FrontpageWidget,
	TermsAndConditions as TermsAndConditionsWidget,
} from "@/pages/clientWidgets";

// This is how react_on_rails can see the Login components in the browser.
strictRegister({
	EmptyWidget,
	Footer,
	FormattedTextWidget,
	FrontpageWidget,
	TermsAndConditionsWidget,
});

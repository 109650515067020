import type { ReactNode } from "react";
import type { SharedWidgetConfiguration } from "./types";
import { widgetClasses, widgetStyle } from "./widget/utils";

type Props = SharedWidgetConfiguration & {
	children?: ReactNode;
};

export function SimpleWidget(props: Props): JSX.Element {
	const { children, widget, display, customisation, view } = props;

	const { id, type } = widget;
	const { widgetColour } = customisation;
	const { emulatingUser } = view;

	return (
		<div
			id={`widget${id}`}
			className={widgetClasses(display, type, emulatingUser)}
			style={widgetStyle(widgetColour)}
		>
			{children}
		</div>
	);
}

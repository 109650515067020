import type { ReactNode } from "react";
import type { LinkGroups, SharedWidgetConfiguration } from "./types";
import { widgetClasses, widgetStyle } from "./widget/utils";
import { WidgetFooter } from "./widget/WidgetFooter";
import { WidgetHeading } from "./widget/WidgetHeading";
import { WidgetHelp } from "./widget/WidgetHelp";

type Props = SharedWidgetConfiguration & {
	children?: ReactNode;
	footerLinks?: LinkGroups;
};

export function Widget(props: Props): JSX.Element {
	const {
		children,
		footerLinks = {},
		widget,
		display,
		customisation,
		view,
	} = props;

	const {
		id,
		type,
		headerTitle,
		headerLinkPath,
		headerImagePath,
		headerImageAltText,
		textilizedHelp,
	} = widget;

	const { widgetColour, headingColour, helpIconAltText, closeText } =
		customisation;
	const { emulatingUser } = view;

	return (
		<div
			id={`widget${id}`}
			className={widgetClasses(display, type, emulatingUser)}
			style={widgetStyle(widgetColour)}
		>
			<WidgetHeading
				title={headerTitle}
				colour={headingColour}
				titleLink={headerLinkPath}
				imagePath={headerImagePath}
				imageAltText={headerImageAltText}
			/>
			<div className="widgetbody roundedbottom">
				<WidgetHelp
					helpIconAltText={helpIconAltText}
					textilizedText={textilizedHelp}
					closeText={closeText}
				/>
				<div className="widgetcontents">{children}</div>
				<WidgetFooter links={footerLinks} />
			</div>
		</div>
	);
}

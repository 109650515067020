import type { WidgetConfiguration } from "../types";
import { Widget } from "../Widget";

type Custom = Record<string, never>;

type Props = WidgetConfiguration<Custom>;

export function Empty(props: Props): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { custom, ...rest } = props;

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Widget {...rest} />;
}

import { createMarkup } from "@flexmr/utils";
import { Modal } from "antd";
import * as React from "react";
import styled from "styled-components";
import { CloseButton } from "./widgetHelp/CloseButton";
import { getClassNames as getIconClassNames } from "@/components/Icon";

type Props = {
	textilizedText: string | null;
	helpIconAltText: string;
	closeText?: string;
};

const IconImage = styled.img`
	float: left;
`;

const TextBlock = styled.div`
	margin-left: 100px;
	min-height: 80px;
	max-height: 300px;
	overflow-y: auto;
	padding-right: 24px;
`;

export function WidgetHelp({
	textilizedText,
	helpIconAltText,
	closeText,
}: Props): JSX.Element | null {
	const [isVisible, setIsVisible] = React.useState(false);

	if (!textilizedText) {
		return null;
	}

	return (
		<>
			<Modal
				open={isVisible}
				footer={null}
				onCancel={() => setIsVisible(false)}
				styles={{
					body: {
						padding: "34px 0px 34px 24px",
					},
				}}
				width={500}
				closable={false}
				destroyOnClose
				zIndex={9999}
				centered
			>
				<div data-testid="help-modal">
					<CloseButton onClick={() => setIsVisible(false)} text={closeText} />
					<IconImage src="/images/help.png" alt={helpIconAltText} />
					<TextBlock dangerouslySetInnerHTML={createMarkup(textilizedText)} />
				</div>
			</Modal>
			<div className="widget_helpicon">
				<button
					onClick={() => setIsVisible(true)}
					type="button"
					className={getIconClassNames("question-circle")}
					title="Help"
					aria-label="Help"
				/>
			</div>
		</>
	);
}

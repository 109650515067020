import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";

type Props = {
	onClick: () => void;
	text?: string;
};

const IconSpan = styled.span`
	width: 40px;
	height: 40px;
	line-height: 40px;
`;

export function CloseButton({ onClick, text }: Props): JSX.Element {
	return (
		<button
			aria-label={text}
			className="ant-modal-close"
			type="button"
			onClick={onClick}
		>
			<IconSpan className="ant-modal-close-x">
				<CloseOutlined className="ant-modal-close-icon" />
			</IconSpan>
		</button>
	);
}

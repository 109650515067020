type Props = {
	path: string;
	altText?: string | null;
	titleLink?: string | null;
};

export function HeaderImage({ path, altText, titleLink }: Props): JSX.Element {
	const image = <img src={path} alt={altText ?? undefined} />;

	return (
		<div className="roundedtop widgetheadingimage">
			{titleLink ? <a href={titleLink}>{image}</a> : image}
		</div>
	);
}

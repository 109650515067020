import type { Size } from "../types";

const widthClasses: Record<Size, string> = {
	single: "singlewidth",
	double: "doublewidth",
};

const heightClasses: Record<Size, string> = {
	single: "singleheight",
	double: "doubleheight",
};

export function getWidthClass(width: Size): string | null {
	return widthClasses[width] || null;
}

export function getHeightClass(height: Size): string | null {
	return heightClasses[height] || null;
}

export function widgetClasses(
	display: { width: Size; height: Size; position: number },
	type: string,
	emulatingUser: boolean,
): string {
	const { width, height, position } = display;

	const classes = [
		"widget",
		type,
		"fixedwidget",
		type !== "carousel" ? "rounded" : null,
		`widget_position${position}`,
		emulatingUser ? "emulating_user" : null,
		getWidthClass(width),
		getHeightClass(height),
		`widget_${type}`,
	];

	return classes.filter((val) => val !== null).join(" ");
}

export function widgetStyle(widgetColour: string): {
	backgroundColor: string;
	borderColor: string;
} {
	return {
		backgroundColor: `#${widgetColour}`,
		borderColor: `#${widgetColour}`,
	};
}

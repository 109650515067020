import { Links } from "./widgetFooter/Links";
import type { LinkGroups } from "@/pages/clientWidgets/types";

type Props = {
	links?: LinkGroups;
};

export function WidgetFooter({ links }: Props): JSX.Element {
	return (
		<div className="widgetfooter">
			<Links links={links} />
		</div>
	);
}

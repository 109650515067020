type Props = {
	path: string;
	title: string;
};

export function Link({ path, title }: Props): JSX.Element {
	return (
		<a href={path} tabIndex={0}>
			{title}
		</a>
	);
}

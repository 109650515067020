import { createMarkup } from "@flexmr/utils";
import { SimpleWidget } from "../SimpleWidget";
import type { WidgetConfiguration } from "../types";

type Custom = {
	imagePath: string | null;
	imageAltText: string | null;
	imageLink: string | null;
	textilizedText: string | null;
};

type Props = WidgetConfiguration<Custom>;

export function Frontpage(props: Props): JSX.Element {
	const { custom, ...rest } = props;

	const { imagePath, imageAltText, imageLink, textilizedText } = custom;

	const image = imagePath ? (
		<img src={imagePath} alt={imageAltText ?? undefined} />
	) : null;

	const linkedImage =
		image && imageLink ? <a href={imageLink}>{image}</a> : image;

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<SimpleWidget {...rest}>
			{linkedImage}
			{textilizedText && (
				<div>
					{/* eslint-disable-next-line react/no-danger */}
					<p dangerouslySetInnerHTML={createMarkup(textilizedText)} />
				</div>
			)}
		</SimpleWidget>
	);
}
